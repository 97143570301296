import { Injectable, Inject } from "@angular/core";
import { DOCUMENT } from "@angular/common";
import { Auth, CognitoUser } from "@aws-amplify/auth";

export enum SUPPORTED_AUTH_PARAMETER {
  SMS_OTP_SETUP = "SMS_OTP_SETUP",
  MAGIC_LINK = "MAGIC_LINK",
  EMAIL_OTP_SETUP = "EMAIL_OTP_SETUP",
}

export interface UserMetadata {
  orgIds: string[];
}

@Injectable({
  providedIn: "root",
})
export class AuthService {
  private cognitoUser: CognitoUser & {
    challengeParam: {
      challenge: "PROVIDE_AUTH_PARAMETERS" | "PROVIDE_SECRET_CODE";
    };
  };

  // Get access to window object in the Angular way
  private window: Window;
  private _signInMethod: SUPPORTED_AUTH_PARAMETER;
  constructor(@Inject(DOCUMENT) private document: Document) {
    this.window = this.document.defaultView;
    this._signInMethod = SUPPORTED_AUTH_PARAMETER.EMAIL_OTP_SETUP;
  }

  public async signIn(email: string) {
    this.cognitoUser = await Auth.signIn(email, undefined, {
      source: "signIn",
    });
    console.log("Cognito User", this.cognitoUser);
    return this.cognitoUser.challengeParam.challenge;
  }

  public async provideAuthParameter(signInMethod: SUPPORTED_AUTH_PARAMETER) {
    this._signInMethod = signInMethod;
    const otherClientMetadata =
      signInMethod === SUPPORTED_AUTH_PARAMETER.MAGIC_LINK
        ? {
            redirectUri: this.getRedirectURL(),
            alreadyHaveMagicLink: "no",
          }
        : {};
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(
      this.cognitoUser,
      "__dummy__",
      {
        signInMethod,
        source: "sendCustomChallengeAnswer",
        ...otherClientMetadata,
      }
    );
  }

  public async signOut() {
    await Auth.signOut();
  }

  public async provideSecretLoginCode(
    answer: string,
    signInMethod: SUPPORTED_AUTH_PARAMETER = this._signInMethod
  ) {
    const otherClientMetadata =
      signInMethod === SUPPORTED_AUTH_PARAMETER.MAGIC_LINK
        ? {
            redirectUri: this.getRedirectURL(),
            alreadyHaveMagicLink: "yes",
          }
        : {};
    this.cognitoUser = await Auth.sendCustomChallengeAnswer(
      this.cognitoUser,
      answer,
      {
        ...otherClientMetadata,
        source: "sendCustomChallengeAnswer",
        signInMethod: signInMethod || SUPPORTED_AUTH_PARAMETER.EMAIL_OTP_SETUP,
      }
    );
    console.log("this.cognitoUser", await this.cognitoUser);
    console.log("getUserDetails", await this.getUserDetails());
    console.log("Auth.currentSession()", await Auth.currentSession());
    console.log("getSignInSession", await this.getSignInSession());
    return this.isAuthenticated();
  }

  public getPublicChallengeParameters() {
    return this.cognitoUser?.challengeParam;
  }

  public getSignInMethod(): SUPPORTED_AUTH_PARAMETER {
    return this._signInMethod;
  }

  public async signUp(props: {
    email: string;
    fullName: string;
    // phoneNumber?: string;
  }) {
    const params = {
      username: props.email,
      password: this.getRandomString(30),
      attributes: {
        name: props.fullName,
        "custom:metadata": JSON.stringify({ orgIds: ["org1", "org2"] }),
        // phone_number: props.phoneNumber,
      },
    };
    await Auth.signUp(params);
  }

  private getRandomString(bytes: number) {
    const randomValues = new Uint8Array(bytes);
    this.window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues).map(this.intToHex).join("");
  }

  private intToHex(nr: number) {
    return nr.toString(16).padStart(2, "0");
  }

  public async isAuthenticated() {
    try {
      await Auth.currentSession();
      return true;
    } catch {
      return false;
    }
  }

  public async getSignInSession() {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    return this.cognitoUser.getSignInUserSession();
  }

  public async getUserDetails() {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    return await Auth.userAttributes(this.cognitoUser);
  }

  public async getUserMetadata(): Promise<UserMetadata | null> {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    const attributes = await Auth.userAttributes(this.cognitoUser);
    const metadata = attributes.find(
      (attr) => attr.Name === "custom:metadata"
    )?.Value;
    return metadata && JSON.parse(metadata);
  }

  public async updateUserMetadata(newMetadata: string) {
    if (!this.cognitoUser) {
      this.cognitoUser = await Auth.currentAuthenticatedUser();
    }
    try {
      await Auth.updateUserAttributes(this.cognitoUser, {
        "custom:metadata": newMetadata,
      });
      console.log("Metadata updated successfully");
    } catch (error) {
      console.error("Error updating metadata:", error);
    }
  }

  public getRedirectURL() {
    const current = new URL(this.window.location.href);
    // current.pathname = ""; // Clear the path
    current.hash = ""; // Clear the fragment identifier
    // return `${current.origin}/private`
    return current.href; // Return the full URL
  }
}
