import { Component, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService, SUPPORTED_AUTH_PARAMETER } from '../auth.service';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'app-choose-medium',
  templateUrl: './choose-medium.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ChooseMediumComponent {
  private busy_ = new BehaviorSubject(false);
  public busy = this.busy_.asObservable();

  private waitingOnMagicLink_ = new BehaviorSubject(false);
  public waitingOnMagicLink = this.waitingOnMagicLink_.asObservable();

  private choice_ = new BehaviorSubject<SUPPORTED_AUTH_PARAMETER>(undefined);
  public choice = this.choice_.asObservable();

  private errorMessage_ = new BehaviorSubject('');
  public errorMessage = this.errorMessage_.asObservable();

  constructor(private router: Router, private auth: AuthService) { }


  public async provideAuthParameter(medium: any) {
    this.choice_.next(medium);
    this.busy_.next(true);
    this.errorMessage_.next('');
    try {
      await this.auth.provideAuthParameter(medium);
      if (medium === SUPPORTED_AUTH_PARAMETER.MAGIC_LINK) {
        this.waitingOnMagicLink_.next(true);
      } else {
        this.router.navigate(['/enter-secret-code']);
      }
    } catch (err) {
      this.errorMessage_.next(err.message || err);
    } finally {
      this.busy_.next(false);
    }
  }
}
