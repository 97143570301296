import { Injectable } from '@angular/core';
import { CanActivate, Router, UrlTree, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AuthService, SUPPORTED_AUTH_PARAMETER } from './auth.service';
import { checkCurrentLocationForSignInLink } from './utils';

@Injectable({
  providedIn: 'root',
})
export class IsAuthenticated implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    if (await this.auth.isAuthenticated()) {
      return true;
    }
    this.router.navigate(['/sign-in']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class IsNotAuthenticated implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  async canActivate(): Promise<boolean> {
    if (!(await this.auth.isAuthenticated())) {
      return true;
    }
    this.router.navigate(['/private']);
    return false;
  }
}

@Injectable({
  providedIn: 'root',
})
export class MagicLinkAuthentication implements CanActivate {
  constructor(private auth: AuthService, private router: Router) {}

  async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean | UrlTree> {
    const urlTree = this.router.parseUrl(state.url);
    const fragment = urlTree.fragment;
    if (fragment) {
      const params = checkCurrentLocationForSignInLink(fragment);
      console.log('fparam', params);
      if (params.exp < Date.now() / 1000) {
        console.info('SIGNIN_LINK_EXPIRED');
        return this.router.createUrlTree(['/']);
      }
      return await this.handleMagicLink(fragment, params.username);
    }
    return true;
  }

  async handleMagicLink(jwtToken: string, username: string): Promise<boolean | UrlTree> {
    try {
      await this.auth.signIn(username);
      const userSession = await this.auth.provideSecretLoginCode(jwtToken, SUPPORTED_AUTH_PARAMETER.MAGIC_LINK);
      console.log(userSession, 'final user');
      return this.router.createUrlTree(['/private']);
    } catch (error) {
      console.error('Error signing in', error);
      return this.router.createUrlTree(['/']);
    }
  }
}
