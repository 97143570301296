<mat-card fxFlex="1 1 auto" ngClass="app-card">

  <mat-card-header>
    <mat-icon mat-card-avatar>lock</mat-icon>
    <mat-card-title>Choose e-mail or SMS</mat-card-title>
    <mat-card-subtitle>Do you want to receive the secret sign-in code via e-mail or SMS?</mat-card-subtitle>
  </mat-card-header>
  <ng-container *ngIf="(waitingOnMagicLink | async); else chooseMeduimTemplate">
    Kindly check your email address for the magic link.
  </ng-container>
  <ng-template #chooseMeduimTemplate>
  <mat-card-actions>
      <div fxFlex></div>
      <button mat-stroked-button color="primary" (click)="provideAuthParameter('EMAIL_OTP_SETUP')" [disabled]="(busy | async)">
        <div fxFlex></div>
        <div *ngIf="!(busy | async)">EMAIL OTP</div>
        <mat-spinner *ngIf="(busy | async) && (choice | async) === 'EMAIL_OTP_SETUP'" [diameter]="35"></mat-spinner>
        <div fxFlex></div>
      </button>
      <button mat-stroked-button color="primary" (click)="provideAuthParameter('MAGIC_LINK')" [disabled]="(busy | async)">
        <div fxFlex></div>
        <div *ngIf="!(busy | async)">MAGIC LINK</div>
        <mat-spinner *ngIf="(busy | async) && (choice | async) === 'MAGIC_LINK'" [diameter]="35"></mat-spinner>
        <div fxFlex></div>
      </button>
      <div fxFlex></div>
    </mat-card-actions>
  </ng-template>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

</mat-card>