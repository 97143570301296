export function bufferFromBase64Url(base64Url: string) {
  // Step 1: Convert Base64URL to Base64
  const base64 = base64Url.replace(/\-/g, '+').replace(/_/g, '/');

  // Step 2: Decode Base64 to a binary string
  const binaryString = atob(base64);

  // Step 3: Create a Uint8Array from the binary string
  const len = binaryString.length;
  const bytes = new Uint8Array(len);
  for (let i = 0; i < len; i++) {
    bytes[i] = binaryString.charCodeAt(i);
  }

  // Step 4: Convert Uint8Array to ArrayBuffer
  return bytes.buffer;
}

export function checkCurrentLocationForSignInLink(fragment: string) {
  const header = fragment.split(".")[0];
  const message = JSON.parse(new TextDecoder().decode(bufferFromBase64Url(header)));

  return {
    username: message.userName,
    exp: message.exp,
    fragment,
  };
}
