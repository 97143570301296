<mat-card fxFlex="1 1 auto" ngClass="app-card">
  <mat-card-header>
    <mat-icon mat-card-avatar>lock</mat-icon>
    <mat-card-title>Please Sign In</mat-card-title>
    <mat-card-subtitle>Enter your e-mail address to initiate sign-in</mat-card-subtitle>
  </mat-card-header>

  <mat-card-content>
    <form fxLayout="column">
      <mat-form-field>
        <input matInput placeholder="E-mail address" [formControl]="email" type="email" name="email"
          autocomplete="username email" />
      </mat-form-field>
    </form>
  </mat-card-content>

  <mat-card-actions>
    <div fxFlex></div>
    <button mat-stroked-button color="primary" (click)="signIn()" [disabled]="busy | async">
      <div fxFlex></div>
      <div *ngIf="!(busy | async)">SIGN-IN</div>
      <mat-spinner *ngIf="busy | async" [diameter]="35"></mat-spinner>
      <div fxFlex></div>
    </button>
    <div fxFlex></div>
  </mat-card-actions>

  <p *ngIf="errorMessage | async">{{ errorMessage | async }}</p>

  <mat-card-content>
    <div fxFlex></div>
    <p>
      Not a member yet?
      <a routerLink="/sign-up" routerLinkActive="active">Sign up</a>
    </p>
  </mat-card-content>
</mat-card>