import { Component, AfterViewInit, ChangeDetectionStrategy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../auth.service';

@Component({
  selector: 'app-sign-out',
  templateUrl: './sign-out.component.html',
  styleUrls: [],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SignOutComponent implements AfterViewInit {
  constructor(private auth: AuthService, private router: Router) {}

  async ngAfterViewInit() {
    await this.auth.signOut();
    this.router.navigate(['/sign-in']);
  }
}
